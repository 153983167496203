/**
 * @section Base Styles
 */

/* Fonts */
@font-face {
    font-family: 'Antenna Medium';
    font-style: normal;
    font-weight: normal;
    src: url('font/Antenna_Medium/Antenna-Medium.ttf');
}

* {
    font-family: 'Antenna Medium';
    font-weight: bold;
}


body {
    padding: 50px;
    @media screen and (max-width: 767px) {
        padding: 0;
    }
}
*:focus {
    outline: none;
}
input,
textarea,
select,
button {
    font: inherit;
    font-family: 'Antenna Medium';
}

.logo__svg {
    fill: #002aff;
    svg {
        width: 100px;
        height: 56px;
    }
}

.logo-ragu,
.logo-communication {
    fill: #002aff;
}

h1 {
    margin: 50px 0;
}

.introText p {
    font-weight: normal;
}

.content-page {
    display: flex;
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    @media screen and (max-width: 767px) {
        flex-direction: column;
    }
}

.jssocials-share-link {
    border-radius: 100% !important;
    background: #00f !important;
}